import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { handleAPI } from "../../API/handleAPI";
// import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import { ReactComponent as BlueSearchIcon } from "../../assets/svg/BlueSearchIcon.svg";
import { ReactComponent as SearchIconYellowBox } from "../../assets/svg/SearchIconYellowBox.svg";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import styles from "../../assets/css/ClientReports.module.css";
import ReportsDownload from "../ClientReports/ReportsDownload";
import { useNavigate } from "react-router-dom";
import { jwtError } from "../../utils/ErrorConditions";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";


function ClientReportsAdmin(props) {
  const navigate = useNavigate();

  const [searchData, setSearchData] = useState([]);
  const [searchLoader, setSearchLoader] = useState(false);
  //console.log("props token in client Report admin", props.token);
  useEffect(() => {
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else if (props.token) {
      handleAPI("/admin/getDashboardSearchResults", {}, props.token)
        .then((response) => {
          setSearchData(response.data.investorData);
          //console.log(
          //   "Admin client Report search bar result",
          //   response.data.investorData
          // );
          setSearchLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

         
          setSearchLoader(true);
        });
    }
  }, [props.token]);

  useEffect(() => {
    props.setPage("CLIA");
  }, []);

  // creating array of data for search result
  let options = [];
  if (searchLoader) {
    searchData.map((item, index) => {
      let temp = {
        value: `${item.label}${item.PAN}`,
        label: item.label,
        PAN: item.PAN,
      };
      options.push(temp);
    });
  }

  const formatOptionLabel = ({ value, label, PAN }) => (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        margin: "0px 30px 0px 30px",
      }}
    >
      <div>{label}</div>
      <div style={{ marginLeft: "10px", color: "#808080" }}>{PAN}</div>
    </div>
  );

  const [showReportsPage, setShowReportsPage] = useState(false);
  const [clientName, setClientName] = useState();

  const handleChange = (event) => {
    //console.log("Event value: ", event.value);
    if (event.value) {
      setClientName(event);
      setShowReportsPage(true);
    }
  };

  return (
    <>
      {showReportsPage ? (
        <ReportsDownload
          clientName={clientName}
          setShowReportsPage={setShowReportsPage}
        />
      ) : searchLoader ? (
        <Grid
          container
          style={{
            marginTop: "1.3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item style={{ fontWeight: "bolder" }}>
            Search the Investor, we'll fetch the available reports.
          </Grid>
          <Grid
            item
            style={{
              marginTop: "3rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid
              item
              style={{
                backgroundColor: "#FED561",
                height: "2.5rem",
                width: "2.5rem",
                display: "flex",
                alighItems: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BlueSearchIcon />
            </Grid>
            <Grid item style={{ width: "35rem" }}>
              <Select
                defaultValue={null}
                formatOptionLabel={formatOptionLabel}
                options={options}
                onChange={handleChange}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      maxHeight: "45vh",
                      overflowY: "auto",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(ClientReportsAdmin);
