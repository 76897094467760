import React from "react";

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import styles from "../../assets/css/Cat23.module.css";
import DoughnutInvestor from "./DoughnutInvestor";

const convertToCurrency = function standardData(initialData) {
  try {
    let finalData = parseFloat(initialData);
    finalData = finalData.toString();
    finalData = finalData.split(".")[0];
    finalData = parseFloat(finalData).toLocaleString("en-IN");

    return finalData;
  } catch (err) {
    console.log("Error in convert to currency:", err);

    throw err;
  }
};

//Cat 2
function Cat2(props) {
  //console.log("3rd drill", props.cat2Data.middleData.category2);
  let rows = [];
  let tableData = [];
  props?.cat2Data?.middleData?.category2 &&
    Object.keys(props.cat2Data.middleData.category2).map((key) => {
      rows.push({
        schemeName: key,
        currentValue: props.cat2Data.middleData.category2[key].currentValue,
        investedValue: props.cat2Data.middleData.category2[key].investedValue,
        schemeDesc: props.cat2Data.middleData.category2[key].schemeDesc,
      });
      tableData.push({
        schemeName: key,
        currentValue: convertToCurrency(
          props.cat2Data.middleData.category2[key].currentValue
        ),
        investedValue: convertToCurrency(
          props.cat2Data.middleData.category2[key].investedValue
        ),
      });
    });
  //console.log("ROWS CAT2--", rows);

  return (
    <div className={styles.mainDiv}>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
        {props.cat2Data?.categoryWiseTotal?.category2?.investedValue == 0 ? (
          <div className={styles.emptyDiv}></div>
        ) : (
          <DoughnutInvestor
            doughnut={rows}
            total={props?.cat2Data?.categoryWiseTotal?.category2?.investedValue}
            type="invested"
          />
        )}
      </Grid>
      <Grid item xs={4}>
        {tableData.length ? (
          <Table className={styles.tableCss}>
            <TableHead>
              <TableRow>
                <TableCell className={styles.tableCellCss}>
                  Scheme Code
                </TableCell>
                <TableCell className={styles.tableCellCss}>
                  Invested Value
                </TableCell>
                <TableCell className={styles.tableCellCss}>
                  Current Value
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {tableData.map((row) => (
                <TableRow
                  key={row.schemeName + row.investedValue}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>{row.schemeName} </TableCell>
                  <TableCell>{row.investedValue} </TableCell>
                  <TableCell>{row.currentValue} </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <>
            <div className={styles.noInvestmentFirstLineCss}>OOPS !</div>
            <div className={styles.noInvestmentSecondLineCss}>
              You do not have any investments here
            </div>
          </>
        )}
      </Grid>
      <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
        {/* {//console.log(
          "props.cat2Data.categoryWiseTotal.category2.currentValue",
          props.cat2Data.categoryWiseTotal.category2.currentValue
        )} */}
        {props?.cat2Data?.categoryWiseTotal?.category2?.currentValue == 0 ? (
          <div></div>
        ) : (
          <DoughnutInvestor
            doughnut={rows}
            total={props?.cat2Data?.categoryWiseTotal?.category2?.currentValue}
            type="current"
          />
        )}
      </Grid>
    </div>
  );
}

export default Cat2;
