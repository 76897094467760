import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, Title, Tooltip, Legend } from "chart.js/auto";
import { connect } from "react-redux";
import { AMC_DETAILS } from "../../variable";
import { motheDifference } from "../../utils/camelCase";

ChartJS.register(Title, Tooltip, Legend);

function LineGraph(props) {
  const [actualData, setActualData] = React.useState([]);
  const [filteredData, setFilteredData] = React.useState([]);
  const [allData, setAllData] = useState([]);
  let monthDifference =
    motheDifference(new Date(AMC_DETAILS.SCHEME_STARTING_MONTH), new Date()) -
    1;
  const AllTypeOfCategory =
    props?.performanceGraphData && Object.keys(props.performanceGraphData);

  useEffect(() => {
    setActualData(
      allData.filter((eachData) => {
        return eachData.label === props.fundType.name;
      })
    );
  }, [props.fundType, allData]);

  useEffect(() => {
    let actualDataindex = actualData;
    if (actualDataindex[0]) {
      let temp =
        monthDifference > 10
          ? actualData[0].data
          : actualData[0].data.slice(10 - monthDifference, 12);
      actualDataindex[0].data = temp;
      setFilteredData(actualDataindex);
    }
  }, [actualData]);
  useEffect(() => {
    let dataTotal = [];
    let genericCategory = [];
    if (props?.performanceGraphData) {
      for (let index = 0; index < AllTypeOfCategory.length; index++) {
        let specificData = [];
        props?.performanceGraphData[`${AllTypeOfCategory[index]}`] &&
          Object.values(
            props?.performanceGraphData[`${AllTypeOfCategory[index]}`]
          ).map((key) => {
            specificData.push(key);
          });
        genericCategory.push({
          label: AllTypeOfCategory[index],
          data: specificData,
          borderColor: props.fundType.color,
          backgroundColor: props.fundType.color,
          tension: 0.4,
        });
      }

      props?.performanceGraphData?.["TotalPerformanceData"] &&
        Object.values(props.performanceGraphData["TotalPerformanceData"]).map(
          (key) => {
            dataTotal.push(key);
          }
        );

      genericCategory.push({
        label: "Total",
        data: dataTotal,
        borderColor: props.fundType.color,
        backgroundColor: props.fundType.color,
        tension: 0.4,
      });
      setAllData(genericCategory);
    }
  }, [props?.performanceGraphData, props.fundType.color]);

  const chartData = {
    labels:
      monthDifference > 10
        ? Object.keys(
            props?.performanceGraphData?.["TotalPerformanceData"] || {}
          )
        : Object.keys(
            props?.performanceGraphData?.["TotalPerformanceData"] || {}
          ).slice(10 - monthDifference, 12),
    datasets: actualData.length > 0 ? filteredData : [],
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        mode: "index",
        intersect: true,
        // backgroundColor: "rgba(255, 255, 255, 0.8)", // Set the background color of the tooltip
        // titleColor: "#333", // Set the color of the tooltip title
        // bodyColor: "#333", // Set the color of the tooltip body text
        // borderColor: "#ccc", // Set the color of the tooltip border
        borderWidth: 1, // Set the width of the tooltip border
        callbacks: {
          label: function (context) {
            // Customize the tooltip label
            const label = context.dataset.label || "";
            const cleanNumber = context.formattedValue.replace(/,/g, "");
            const a = parseFloat(cleanNumber);
            const b = (a / 1e6).toFixed(2);
            const value = b;
            return `${label}: ${value}M`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          // text: "Date",
        },
        ticks: {
          stepSize: 0,
          maxRotation: 90,
          minRotation: 90,
        },
        grid: {
          display: false, // Hide the vertical grid lines
          // drawTicks: false, // Hide the y-axis ticks
          // drawBorder: false,
        },
      },
      y: {
        display: true,
        min: 0,
        grid: {
          // display: false, // Hide the vertical grid lines
          drawTicks: false, // Hide the y-axis ticks
          drawBorder: true,
        },
        title: {
          display: true,
          // text: "Value",
        },
        ticks: {
          callback: function (value, index, ticks) {
            return formatNumber(value);
          },
        },
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 10,
        left: -10,
      },
    },
  };

  return <Line data={chartData} options={options} height={200} />;
}

function formatNumber(value) {
  if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "M";
  } else {
    return value.toFixed(0);
  }
}

const mapStatetoProps = (state) => {
  return {
    fundType: state.mainReducer.fundType,
  };
};

export default connect(mapStatetoProps)(LineGraph);
