import React from "react";
import Cat2 from "../InvestorCat2And3/Cat2";
import Cat3 from "../InvestorCat2And3/Cat3";

function ValuePreviewTable(props) {
  //console.log("2nd drill", props.catBox);
  return (
    <>
      {props.flag ? (
        <Cat3 cat3Data={props.catBox} />
      ) : (
        <Cat2 cat2Data={props.catBox} />
      )}
    </>
  );
}

export default ValuePreviewTable;
