import React from "react";
import Grid from "@mui/material/Grid";
import { Card, LinearProgress, Tooltip } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import parseUrl from "../utils/parseURL";
import { useState, useEffect } from "react";
import { handleAPI } from "../API/handleAPI";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { connect } from "react-redux";
import CardComponent from "./CardComponent";
import Graphs from "./InvestorGraphs/Graphs";
import CurrentInvestedValue from "./InvestorCat2And3/CurrentInvestedValue";
import { ReactComponent as LoadingBlack } from "../assets/svg/LoadingBlack.svg";
import styles from "../assets/css/Investor.module.css";
import Loader from "../views/Loader";
import Swal from "sweetalert2";
import { popupError } from "../utils/errorHandler";
import { AMC_DETAILS } from "../variable";

function Investor(props) {
  let [catBox, setCatBox] = useState({});
  let [loaderNavDate, setLoaderNavDate] = useState(false);
  let [loaderPortfolio, setLoaderPortfolio] = useState(false);
  let [portfolioBox, setPortfolioBox] = useState({
    portfolioView: [],
    portfolioViewTotal: "0",
  });
  let [loaderBubbleBox, setLoaderBubbleBox] = useState(false);
  let [bubbleBox, setBubbleBox] = useState({
    ["Current Value"]: "",
    ["Total Commitment"]: "",
    ["Total Contribution Raised"]: "",
    ["Uncalled Contribution"]: "",
    ["Payout/Distribution"]: "",
  });
  let [performanceData, setPerformanceData] = useState({});
  let [benchmark, setBenchmark] = useState({});
  let [performanceGraphData, setPerformanceGraphData] = useState(null);
  let [loaderDashboardPerformanceData, setLoaderDashboardPerformanceData] =
    useState(false);
  let [loaderDashboardPerformanceGraph, setLoaderDashboardPerformanceGraph] =
    useState(false);
  let [loaderUnread, setLoaderUnread] = useState();
  const navigate = useNavigate();

  console.log("User Details are :", props.userDetails);
  console.log("Type of investor is :", props.userDetails.accountType);
  console.log("Before API", props.query);

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/investor/getNavDate",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("NAV DATE RECENT----", response.data);

          props.setNavDate(response.data);
          setLoaderNavDate(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderNavDate(true);
        });

      handleAPI(
        "/investor/getDashboardBubbleBox",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          setBubbleBox(response.data);
          setLoaderBubbleBox(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");

          setLoaderBubbleBox(true);
        });
      handleAPI(
        "/investor/getDashboardCategoryBox",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("Cat box data----", response.data);
          setCatBox(response.data);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
        });
      handleAPI(
        "/investor/getDashboardCategoryBox",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("Cat box data----", response.data);
          setCatBox(response.data);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
        });
      handleAPI(
        "/investor/getUpdateAnnouncementUnseenNumber",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("Unread data----", response.data.unseenFiles);
          props.setUnread(response.data.unseenFiles);
          setLoaderUnread(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoaderUnread(true);
        });
      handleAPI(
        "/investor/getDashboardPortfolioView",
        {
          // pan: props.userDetails.pan,
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          console.log("Portfolio view details", response.data);
          setPortfolioBox(response.data);
          setLoaderPortfolio(true);
        })
        .catch((error) => {
          setLoaderPortfolio(true);
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
        });

      handleAPI(
        "/investor/getDashboardPerformanceData",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          setPerformanceData(response.data);
          setLoaderDashboardPerformanceData(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoaderDashboardPerformanceData(true);
        });

      handleAPI(
        "/investor/getDashboardPerformanceGraph",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          setPerformanceGraphData(response.data);
          setLoaderDashboardPerformanceGraph(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          setLoaderDashboardPerformanceGraph(true);
        });
    }
  }, [props.userDetails.pan]);

  useEffect(() => {
    props.setShowTLNav(true);
    props.setPage("INV");
    // window.history.pushState(null, null);
    // window.history.pushState(null, null, window.location.href);
    // window.onpopstate = function () {
    //   console.log("run on back button click");
    //   window.history.go(1);
    // };
  }, []);

  const CommitmentHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const ContributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },

    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const ContributionHeadCellsOpenEnded = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },

    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    // {
    //   field: "commitmentAmount",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "Commitment Amount",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 178,
    // },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const DistributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const DistributionHeadCellsPOpenEnded = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const CurrentValueHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },

    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "unitsAllocated",
      numeric: true,
      disablePadding: false,
      headerName: "Units Allocated",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "currentNav",
      numeric: true,
      disablePadding: false,
      headerName: "Current Nav",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const UncalledContributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      align: "center",
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      align: "center",
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "uncalledContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Uncalled Contribution",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const Cat23HeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },

    {
      field: "totalCapitalCommitment",
      numeric: true,
      disablePadding: false,
      headerName: "Total Capital Commitment",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerAlign: "center",
      align: "center",
      headerName: "Total Contribution",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "contributionDate",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Date",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },

    {
      field: "entryNAV",
      numeric: true,
      disablePadding: false,
      headerName: "Entry Nav",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];
  const Cat23HeadCellsOpenEnded = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "shareClass",
      numeric: true,
      disablePadding: false,
      headerName: "Share Class",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerAlign: "center",
      align: "center",
      headerName: "Total Contribution",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
    {
      field: "contributionDate",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Date",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },

    {
      field: "entryNAV",
      numeric: true,
      disablePadding: false,
      headerName: "Entry Nav",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
      align: "center",
    },
  ];

  return (
    <div>
      {/* {showLoader && <Loader />} */}
      {loaderNavDate &&
      loaderBubbleBox &&
      loaderPortfolio &&
      loaderDashboardPerformanceData &&
      loaderDashboardPerformanceGraph ? (
        <Grid container>
          <Grid container className={styles.mainBody}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                fontSize: "10px",
                color: "#6d6e71",
                // border: "solid 2px red",
              }}
            >
              Updated as of NAV dated {props.navDate}
            </Grid>
            {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" && (
              <Grid item xs={4} className={styles.card}>
                <CardComponent
                  cardTitle1="Total Commitment"
                  cardContent={bubbleBox["Total Commitment"]}
                  headCells={CommitmentHeadCells}
                  // data={CommitmentData}
                  name="TotalCommitment"
                  detailHeader="Commitment Details"
                />
              </Grid>
            )}
            <Grid item xs={4} className={styles.card}>
              <CardComponent
                cardTitle1="Total Contribution"
                cardContent={bubbleBox["Total Contribution Raised"]}
                headCells={
                  AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                    ? ContributionHeadCellsOpenEnded
                    : ContributionHeadCells
                }
                // data={ContributiontData}
                name="TotalContribution"
                detailHeader="Contribution Details"
              />
            </Grid>
            {AMC_DETAILS.PAYOUT_DISTRUBUTION && (
              <Grid item xs={4} className={styles.card}>
                <CardComponent
                  cardTitle1="Payout/Distribution"
                  cardContent={bubbleBox["Payout/Distribution"]}
                  headCells={
                    AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                      ? DistributionHeadCellsPOpenEnded
                      : DistributionHeadCells
                  }
                  // data={DistributiontData}
                  name="IncomeDistribution"
                  detailHeader="Distribution Details"
                />
              </Grid>
            )}
            {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" ? (
              <Grid item xs={12} className={styles.cardBottomCommon}>
                <Grid item xs={4} className={styles.cardBottomLeft}>
                  <CardComponent
                    cardTitle1="Current Value"
                    cardContent={bubbleBox["Current Value"]}
                    headCells={CurrentValueHeadCells}
                    // data={CurrentValueData}
                    name="CurrentValue"
                    detailHeader="Investment Details"
                  />
                </Grid>

                <Grid item xs={4} className={styles.cardBottomRight}>
                  <CardComponent
                    cardTitle1="Uncalled Contributions"
                    cardContent={bubbleBox["Uncalled Contribution"]}
                    headCells={UncalledContributionHeadCells}
                    // data={UncalledContributiontData}
                    name="UncalledContribution"
                    detailHeader="Uncalled Contribution Details"
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={4} className={styles.card}>
                <CardComponent
                  cardTitle1="Current Value"
                  cardContent={bubbleBox["Current Value"]}
                  headCells={CurrentValueHeadCells}
                  // data={CurrentValueData}
                  name="CurrentValue"
                  detailHeader="Investment Details"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className={styles.graphs}>
            <Graphs
              portfolioBox={portfolioBox}
              performanceData={performanceData}
              performanceGraphData={performanceGraphData}
              benchmark={benchmark}
            />
          </Grid>
          <Grid item xs={12} className={styles.cat23Grid}>
            <Card variant="outlined" className={styles.cat23Card}>
              <CurrentInvestedValue
                catBox={catBox}
                Cat23HeadCells={
                  AMC_DETAILS.INVESTMENT_TYPE == "openEnded"
                    ? Cat23HeadCellsOpenEnded
                    : Cat23HeadCells
                }
              />
            </Card>
          </Grid>
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    page: state.mainReducer.page,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
    query: state.mainReducer.query,
    unread: state.mainReducer.unread,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setNavDate: (val) => {
      dispatch({ type: "CHANGE-NAV-DATE", navDate: val });
    },
    setQuery: (val) => {
      dispatch({ type: "CHANGE-QUERY", query: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
    setShowTLNav: (val) => {
      dispatch({ type: "CHANGE-SHOW-TLNAV", showTLNav: val });
    },
    setShowLoader: (val) => {
      dispatch({ type: "CHANGE-SHOW-LOADER", showLoader: val });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Investor);
