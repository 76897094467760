import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import camelCase from "../../utils/camelCase";
import { styled } from "@mui/material/styles";
import { COLOR_CODE } from "../../variable";

export default function UATableModal(props) {
  let rows = [];
  let cols = props.headCells;
  //console.log("props in UATABLE modal", props);

  props.data.map((data) => {
    let e = {};
    Object.keys(data).map((key) => {
      e[camelCase(key)] = data[key];
    });
    rows.push(e);
  });
  rows.map((row, index) => (row.id = `${index + 1}`));
  cols.map((col, index) => (col["key"] = `${index + 1}`));
  //console.log("rows--------------", rows);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
  }));
  return (
    <Box
      sx={{
        height: 337,
        width: "100%",
        "& .tableModal--Header": {
          backgroundColor: COLOR_CODE.TABLE_HEADER,
          color: "white",
          fontFamily: "Lato",
        },
      }}
    >
      <StyledDataGrid
        style={{ fontFamily: "Lato" }}
        rows={rows}
        columns={cols}
        pageSize={4}
        rowsPerPageOptions={[4]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
}
