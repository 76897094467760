import { Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import { handleAPI } from "../../API/handleAPI";
import Marquee from "react-fast-marquee";
import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import styles from "../../assets/css/RecentTransaction.module.css";
import { useNavigate } from "react-router-dom";
import Loader from "../../views/Loader";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";

function TickerAdmin(props) {
  useEffect(() => {
    props.setPage("tickerMessage");
  }, []);
  const [tickerString0, setTickerString0] = useState("");
  const [tickerString1, setTickerString1] = useState("");
  const [tickerString2, setTickerString2] = useState("");
  const [tickerString3, setTickerString3] = useState("");
  const [tickerString4, setTickerString4] = useState("");

  const [finalTicker, setFinalTicker] = useState([]);
  const [showTicker, setShowTicker] = useState([]);
  const [newTickerMsg, setNewTickerMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleChange0 = (event) => {
    setTickerString0(event.target.value);
  };
  const handleChange1 = (event) => {
    setTickerString1(event.target.value);
  };
  const handleChange2 = (event) => {
    setTickerString2(event.target.value);
  };
  const handleChange3 = (event) => {
    setTickerString3(event.target.value);
  };
  const handleChange4 = (event) => {
    setTickerString4(event.target.value);
  };

  useEffect(() => {
    if (props.userDetails.permissions == "") {
      navigate("/admin/profile");
    } else if (props.token) {
      handleAPI("/admin/getTickerMessage", {}, props.token)
        .then((response) => {
          //console.log("Ticker message get Ticker", response);
          setTickerString0(response.data[0] ? response.data[0] : "");
          setTickerString1(response.data[1] ? response.data[1] : "");
          setTickerString2(response.data[2] ? response.data[2] : "");
          setTickerString3(response.data[3] ? response.data[3] : "");
          setTickerString4(response.data[4] ? response.data[4] : "");

          const tempArr = Object.values(response.data);
          //console.log("tempArr :>> ", tempArr);

          setFinalTicker(tempArr);
          setLoader(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);

          setLoader(true);
        });
    }
  }, [props.token]);

  function createTickerMsg() {
    //console.log("finalTicker ------>", finalTicker);
    setNewTickerMsg(
      <div style={{ display: "flex", flexDirection: "row" }}>
        {finalTicker.map((message, index) => (
          <Typography key={index} style={{ whiteSpace: "nowrap" }}>
            {" "}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;▲
            {message}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </Typography>
        ))}
      </div>
    );
  }

  useEffect(() => {
    createTickerMsg();
  }, [finalTicker]);

  useEffect(() => {
    setShowTicker(false);
    const newStringArr = [];
    if (tickerString0 && tickerString0?.length !== 0) {
      newStringArr.push(tickerString0);
    }
    if (tickerString1 && tickerString1?.length !== 0) {
      newStringArr.push(tickerString1);
    }
    if (tickerString2 && tickerString2?.length !== 0) {
      newStringArr.push(tickerString2);
    }
    if (tickerString3 && tickerString3?.length !== 0) {
      newStringArr.push(tickerString3);
    }
    if (tickerString4 && tickerString4?.length !== 0) {
      newStringArr.push(tickerString4);
    }

    setFinalTicker(newStringArr);
  }, [
    tickerString0,
    tickerString1,
    tickerString2,
    tickerString3,
    tickerString4,
  ]);

  async function updateTicker() {
    if (props.token) {
      await handleAPI(
        "/admin/updateTickerMessage",
        {
          tickerMessage: {
            0: tickerString0,
            1: tickerString1,
            2: tickerString2,
            3: tickerString3,
            4: tickerString4,
          },
          user: props.userDetails.fullName,
        },
        props.token
      )
        .then((response) => {
          console.log("Ticker message get Ticker");
          // setTableData2(response.data);
          // setDataAll2(response.data);
          // setDistributorRelated(
          //   response.data.recentTransactionData.Contribution
          // );
          // setServiceRequest(response.data.recentTransactionData.Redemption);
          // setRows(response.data.recentTransactionData.All);
          // setLoaderHistory(true);
        })

        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
        });
    }
    setShowTicker(false);
  }

  function previewButton() {
    if (finalTicker.length >= 0) {
      setShowTicker(true);
    }
  }
  //console.log("Ticker User Permission Tab :", props.userPermission);
  return (
    <div style={{ marginTop: "20px", marginLeft: "30px" }}>
      {loader ? (
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              background: "#89C4F4",
              // "linear-gradient(90.68deg, #AD9042 0.59%, #CFAD4F 59.57%, #DEBA55 82.74%, #FED561 99.41%)",
            }}
          >
            {showTicker ? (
              <Marquee speed={50} pauseOnHover={true}>
                <>
                  <div>
                    {/* <span style={{color:"white"}}> */}
                    {newTickerMsg}
                    {/* </span> */}
                  </div>
                </>
              </Marquee>
            ) : (
              <></>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: "800",
              marginTop: "10px",
            }}
          >
            Update upto 5 announcements, which will be visible to investors and
            distributors
          </Grid>
          <Grid item xs={12} style={{ marginRight: "50px" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                sx={{ m: 1 }}
                onChange={handleChange0}
                value={tickerString0}
                size="small"
                id="filled-basic"
                label="Enter message"
                variant="filled"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={handleChange1}
                value={tickerString1}
                fullWidth
                sx={{ m: 1 }}
                size="small"
                id="filled-basic"
                label="Enter message"
                variant="filled"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={handleChange2}
                value={tickerString2}
                fullWidth
                sx={{ m: 1 }}
                size="small"
                id="filled-basic"
                label="Enter message"
                variant="filled"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                onChange={handleChange3}
                value={tickerString3}
                fullWidth
                sx={{ m: 1 }}
                size="small"
                id="filled-basic"
                label="Enter message"
                variant="filled"
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <TextField
                fullWidth
                onChange={handleChange4}
                value={tickerString4}
                sx={{ m: 1 }}
                size="small"
                id="filled-basic"
                label="Enter message"
                variant="filled"
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ marginTop: "20px" }}>
            <div className="downloadBtn">
              <button
                style={{ backgroundColor: "#333333" }}
                className="btncss"
                onClick={previewButton}
              >
                Preview
              </button>
              <button
                onClick={updateTicker}
                style={{ backgroundColor: "#333333" }}
                className={
                  showTicker && ["all", "write"].includes(props.userPermission)
                    ? "btncss"
                    : "btncss-disable"
                }
                disabled={
                  ["all", "write"].includes(props.userPermission)
                    ? !showTicker
                    : true
                }
              >
                <span style={{ color: "#ffffff" }}>Confirm</span>
              </button>
            </div>
          </Grid>
        </Grid>
      ) : (
        // <LoadingBlack className={styles.loaderCss} />
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
    userPermission: state.mainReducer.userPermission,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};
export default connect(mapStatetoProps, mapDispatchtoProps)(TickerAdmin);
