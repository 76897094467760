import * as React from "react";
import Grid from "@mui/material/Grid";
import styles from "../../../assets/css/GraphsDistributor.module.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Cat2 from "../../../assets/svg/cat2_white.svg";
import Cat3 from "../../../assets/svg/cat3_white.svg";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import InvestmentDoughnut from "./InvestmentDoughnut";
import DashboardTable from "../../DashboardTable";
import InvestmentsExtension from "./InvestmentsExtension";
import { AMC_DETAILS } from "../../../variable";

const Android12Switch = styled(Switch)(({ theme }) => ({
  height: 45,
  width: 160,
  padding: 8,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(72px)",
      "& .MuiSwitch-thumb": {
        backgroundImage: `url(${Cat3})`,
        opacity: 1,
        border: 0,
        content: "''",
        position: "absolute",
        left: 0,
        top: 7,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#aab4be",
      },
    },
    ".MuiSwitch-thumb": {
      backgroundImage: `url(${Cat2})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      left: 0,
      top: 7,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  "& .MuiSwitch-track": {
    borderRadius: 30 / 2,
    opacity: 1,
    backgroundColor: "#aab4be",
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 36,
      height: 26,
    },
    "&:before": {
      backgroundImage: `url(${Cat3})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      top: 15,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      right: 25,
    },
    "&:after": {
      backgroundImage: `url(${Cat2})`,
      opacity: 1,
      border: 0,
      content: "''",
      position: "absolute",
      top: 15,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom",
      left: 25,
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: "#2057A6",
    borderRadius: 30 / 2,
    width: 80,
    height: 30,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
}));

function Investments(props) {
  const InvestorHeadCells = [
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "left",
    },
    {
      field: "numberOfInvestors",
      numeric: true,
      disablePadding: false,
      headerName: "Number Of Investors",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },

    {
      field: "totalCapitalCommitment",
      numeric: true,
      disablePadding: false,
      headerName: "Total Capital Commitment",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Total Contribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 140,
    },
    {
      field: "aUM",
      numeric: true,
      disablePadding: false,
      headerName: "AUM",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "uncalledContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Uncalled Contribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      // width: 320,
      width: 150,
    },
  ];
  const InvestorHeadCellsOpenEnded = [
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
      align: "left",
    },
    {
      field: "numberOfInvestors",
      numeric: true,
      disablePadding: false,
      headerName: "Number Of Investors",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "totalContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Total Contribution",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 240,
    },
    {
      field: "aUM",
      numeric: true,
      disablePadding: false,
      headerName: "AUM",
      headerAlign: "center",
      align: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];

  const [flag, setFlag] = useState(
    AMC_DETAILS.FUND_CATEGORY[0] == "CAT-III" ? true : false
  );
  const handelCheck = () => {
    setFlag(flag ? false : true);
  };

  return (
    <div>
      <Grid
        container
        style={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Card
          variant="outlined"
          style={{ height: "430px", width: "450px" }}
          className={styles.card}
        >
          <CardContent className={styles.InvestmentsHeader}>
            <Grid item xs={12} className={styles.lineGrid__row1}>
              <Grid
                item
                xs={4}
                className={styles.cardHeader}
                style={{
                  marginLeft: "0.3rem",
                  marginTop: "0.8rem",
                  // border: "solid 2px red",
                }}
              >
                Investments
              </Grid>
              <Grid
                item
                xs={8}
                className={styles.switch}
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                }}
              >
                {AMC_DETAILS.FUND_CATEGORY?.length > 1 && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Android12Switch
                          checked={flag}
                          onChange={handelCheck}
                        />
                      }
                    />
                  </FormGroup>
                )}
              </Grid>
            </Grid>
          </CardContent>

          {/* {//console.log("firstqwe", props.doughnutData)} */}

          <CardContent>
            <Grid
              item
              xs={12}
              style={{
                // display: "flex",
                height: "auto",
              }}
            >
              <InvestmentDoughnut
                doughnutData={props.doughnutData}
                flag={flag}
              />
            </Grid>
          </CardContent>
          <CardContent>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "flex-end",
                height: "auto",
                justifyContent: "right",
                paddingTop: "1rem",
                // border: "solid 2px red",
              }}
            >
              <InvestmentsExtension
                name="InvestmentCategory"
                headCells={
                  AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                    ? InvestorHeadCellsOpenEnded
                    : InvestorHeadCells
                }
                // data={props.data}
                flag={flag}
                detailHeader="Investment Details"
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
}

export default Investments;
