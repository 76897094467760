import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import camelCase from "../../utils/camelCase";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    borderRight: `1px solid ${
      theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
    }`,
  },
}));

function ReportsDownloadTable(props) {
  let rows = [];
  let cols = props.headCells;

  //console.log("props.data in reports download table ", props);

  props.data.map((data) => {
    let e = {};
    Object.keys(data).map((key) => {
      e[camelCase(key)] = data[key];
    });
    rows.push(e);
  });

  rows.map((row, index) => (row.id = `${index + 1}`));
  cols.map((col, index) => (col["key"] = `${index + 1}`));
  //console.log("cols--", cols);
  //console.log("rows--", rows);

  return (
    <>
      <StyledDataGrid
        style={{ fontFamily: "Lato" }}
        rows={rows}
        columns={cols}
        pageSize={6}
        rowsPerPageOptions={[6]}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </>
  );
}

export default ReportsDownloadTable;
