import React from "react";
import CardComponent from "../CardComponent";

import { useState } from "react";
import styles from "../../assets/css/Investor.module.css";
import { useEffect } from "react";
import { handleAPI } from "../../API/handleAPI";
import { connect } from "react-redux";
import { ReactComponent as Back } from "../../assets/svg/back.svg";
// import { ReactComponent as LoadingBlack } from "../../assets/svg/LoadingBlack.svg";
import Loader from "../../views/Loader";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { authStore } from "../../utils/authStore";
import { AMC_DETAILS } from "../../variable";
//import { popupError } from "../../utils/errorHandler";
function AdminInvestor(props) {
  useEffect(() => {
    props.setPage("ADM");
  }, []);
  //console.log("ClientName", props.clientName.PAN);
  //console.log("Tokennn", props.token);
  let [loaderBubbleBox, setLoaderBubbleBox] = useState(false);
  let [bubbleBox, setBubbleBox] = useState({
    ["Current Value"]: "",
    ["Total Commitment"]: "",
    ["Total Contribution Raised"]: "",
    ["Uncalled Contribution"]: "",
    ["Payout/Distribution"]: "",
  });
  let [loaderNavDate, setLoaderNavDate] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    handleAPI(
      "/investor/getNavDate",
      {
        pan: props.clientName.PAN,
      },
      props.token
    )
      .then((response) => {
        //console.log("NAV DATE RECENT----", response.data);

        props.setNavDate(response.data);
        setLoaderNavDate(true);
      })

      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);
        setLoaderNavDate(true);
      });
  }, [props.userDetails.pan]);

  useEffect(() => {
    handleAPI(
      "/investor/getDashboardBubbleBox",
      {
        pan: props.clientName.PAN,
      },
      props.token
    )
      .then((response) => {
        setBubbleBox(response.data);
        setLoaderBubbleBox(true);
      })
      .catch((error) => {
        console.log(`Error Message:  ${error.errorMessage}`);

        setLoaderBubbleBox(true);
      });
  }, []);
  useEffect(() => {
    props.setAdmUser("INV");
  }, []);
  const CommitmentHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const ContributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },

    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const DistributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const CurrentValueHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },

    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "unitsAllocated",
      numeric: true,
      disablePadding: false,
      headerName: "Units Allocated",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "currentNav",
      numeric: true,
      disablePadding: false,
      headerName: "Current Nav",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "currentValue",
      numeric: true,
      disablePadding: false,
      headerName: "Current Value",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  const UncalledContributionHeadCells = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      align: "center",
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      align: "center",
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "commitmentAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Commitment Amount",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      align: "center",
      field: "uncalledContribution",
      numeric: true,
      disablePadding: false,
      headerName: "Uncalled Contribution",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const DistributionHeadCellsPOpenEnded = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 110,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 85,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },
    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "incomeDistribution",
      numeric: true,
      disablePadding: false,
      headerName: "Income Distribution",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "capitalRepayment",
      numeric: true,
      disablePadding: false,
      headerName: "Capital Repayment",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];
  const ContributionHeadCellsOpenEnded = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "investorName",
      numeric: true,
      disablePadding: false,
      headerName: "Investor Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "secondHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Second Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "thirdHolder",
      numeric: true,
      disablePadding: false,
      headerName: "Third Holder",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "category",
      numeric: true,
      disablePadding: false,
      headerName: "Category",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 160,
    },
    {
      field: "class",
      numeric: true,
      disablePadding: false,
      headerName: "Class",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 80,
    },

    {
      field: "agentName",
      numeric: true,
      disablePadding: false,
      headerName: "Agent Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
    // {
    //   field: "commitmentAmount",
    //   numeric: true,
    //   disablePadding: false,
    //   headerName: "Commitment Amount",
    //   align: "center",
    //   headerAlign: "center",
    //   headerClassName: "tableModal--Header",
    //   width: 178,
    // },
    {
      field: "contributionAmount",
      numeric: true,
      disablePadding: false,
      headerName: "Contribution Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 178,
    },
  ];

  return (
    <div>
      {loaderBubbleBox && loaderNavDate ? (
        <Grid container className={styles.mainBody}>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              fontSize: "10px",
              color: "#6d6e71",
              // border: "solid 2px red",
            }}
          >
            Updated as of NAV dated {props.navDate}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item xs={1}>
              <Button
                onClick={() => {
                  props.setShowDashboardPage(false);
                }}
              >
                <Back />
              </Button>
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                display: "flex",
                justifyContent: "start",
                flexDirection: "column",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  color: "grey",
                  fontSize: "12px",
                }}
              >
                {" "}
                Investment details available for{" "}
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  fontSize: "14px",
                }}
              >
                <b>{props.clientName.label} </b>
              </Grid>
            </Grid>
          </Grid>
          {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" && (
            <Grid item xs={4} className={styles.card}>
              <CardComponent
                cardTitle1="Total Commitment"
                cardContent={bubbleBox["Total Commitment"]}
                headCells={CommitmentHeadCells}
                // data={CommitmentData}
                name="TotalCommitment"
                detailHeader="Commitment Details"
              />
            </Grid>
          )}
          <Grid item xs={4} className={styles.card}>
            <CardComponent
              cardTitle1="Total Contribution"
              cardContent={bubbleBox["Total Contribution Raised"]}
              headCells={
                AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                  ? ContributionHeadCellsOpenEnded
                  : ContributionHeadCells
              }
              // data={ContributiontData}
              name="TotalContribution"
              detailHeader="Contribution Details"
            />
          </Grid>
          <Grid item xs={4} className={styles.card}>
            <CardComponent
              cardTitle1="Payout/Distribution"
              cardContent={bubbleBox["Payout/Distribution"]}
              headCells={
                AMC_DETAILS.INVESTMENT_TYPE === "openEnded"
                  ? DistributionHeadCellsPOpenEnded
                  : DistributionHeadCells
              }
              // data={DistributiontData}
              name="IncomeDistribution"
              detailHeader="Distribution Details"
            />
          </Grid>
          {AMC_DETAILS.INVESTMENT_TYPE !== "openEnded" ? (
            <Grid item xs={12} className={styles.cardBottomCommon}>
              <Grid item xs={4} className={styles.cardBottomLeft}>
                <CardComponent
                  cardTitle1="Current Value"
                  cardContent={bubbleBox["Current Value"]}
                  headCells={CurrentValueHeadCells}
                  // data={CurrentValueData}
                  name="CurrentValue"
                  detailHeader="Investment Details"
                />
              </Grid>

              <Grid item xs={4} className={styles.cardBottomRight}>
                <CardComponent
                  cardTitle1="Uncalled Contributions"
                  cardContent={bubbleBox["Uncalled Contribution"]}
                  headCells={UncalledContributionHeadCells}
                  // data={UncalledContributiontData}
                  name="UncalledContribution"
                  detailHeader="Uncalled Contribution Details"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={4} className={styles.card}>
              <CardComponent
                cardTitle1="Current Value"
                cardContent={bubbleBox["Current Value"]}
                headCells={CurrentValueHeadCells}
                // data={CurrentValueData}
                name="CurrentValue"
                detailHeader="Investment Details"
              />
            </Grid>
          )}
        </Grid>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    userDetails: state.mainReducer.userDetails,
    page: state.mainReducer.page,
    token: state.mainReducer.token,
    navDate: state.mainReducer.navDate,
    query: state.mainReducer.query,
    unread: state.mainReducer.unread,
    admUser: state.mainReducer.admUser,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
    setNavDate: (val) => {
      dispatch({ type: "CHANGE-NAV-DATE", navDate: val });
    },
    setQuery: (val) => {
      dispatch({ type: "CHANGE-QUERY", query: val });
    },
    setUnread: (val) => {
      dispatch({ type: "CHANGE-UNREAD", unread: val });
    },
    setAdmUser: (val) => {
      dispatch({ type: "CHANGE-ADM-USER", admUser: val });
    },
    RESET: () => {
      dispatch({ type: "RESET" });
    },
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(AdminInvestor);
