import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { useState, useEffect } from "react";
import { handleAPI } from "../API/handleAPI";
import styles from "../assets/css/RecentTransaction.module.css";
import RecentTransactionTable from "./RecentTransactionTable";
import Loader from "../views/Loader";
import { useNavigate } from "react-router-dom";
import { AMC_DETAILS } from "../variable";

function RecentTransactionInvestor(props) {
  const [dataAll, setDataAll] = useState([]);
  const [dataContribution, setDataContribution] = useState([]);
  const [dataDistribution, setDataDistribution] = useState([]);
  const [dataRedemption, setDataRedemption] = useState([]);
  const [loader, setLoader] = useState(false);
  const [downloadLink, setDownloadLink] = useState({});
  const [valueSelected, setValueSelected] = useState("All");
  const [rows, setRows] = useState([]);
  const [colsAll, setColsAll] = useState([]);
  const navigate = useNavigate();

  let cols = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },
    {
      field: "nAV",
      numeric: true,
      disablePadding: false,
      headerName: "NAV",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];
  let colsRedemption = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },
    {
      field: "nAV",
      numeric: true,
      disablePadding: false,
      headerName: "NAV",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];
  let colsDistribution = [
    {
      field: "folioNumber",
      numeric: true,
      disablePadding: false,
      headerName: "Folio Number",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "schemeName",
      numeric: true,
      disablePadding: false,
      headerName: "Scheme Name",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 350,
    },
    {
      field: "transactionType",
      numeric: true,
      disablePadding: false,
      headerName: "Transaction Type",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 145,
    },
    {
      field: "date",
      numeric: true,
      disablePadding: false,
      headerName: "Date",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 150,
    },
    {
      field: "amount",
      numeric: true,
      disablePadding: false,
      headerName: "Amount",
      align: "center",
      headerAlign: "center",
      headerClassName: "tableModal--Header",
      width: 200,
    },
  ];
  useEffect(() => {
    props.setPage("RecentTransactionInvestor");
  }, []);

  useEffect(() => {
    if (props.userDetails.pan) {
      handleAPI(
        "/investor/getRecentTransactions",
        {
          pan: props.userDetails.pan,
        },
        props.token
      )
        .then((response) => {
          //console.log("qwerty", response.data.recentTransactionData.All);
          setDataAll(response.data.recentTransactionData.All);
          setDataContribution(response.data.recentTransactionData.Contribution);
          setDataRedemption(response.data.recentTransactionData.Redemption);
          setDataDistribution(response.data.recentTransactionData.Distribution);
          setRows(response.data.recentTransactionData.All);
          setColsAll(cols);
          setDownloadLink({
            xlsxLink: response.data.s3XlsxSignedUrl,
            pdfLink: response.data.s3PdfSignedUrl,
          });
          setLoader(true);
        })
        .catch((error) => {
          console.log(`Error Message:  ${error.errorMessage}`);
          console.log("Redirecting to error");
          setLoader(true);
        });
    }
  }, [props.userDetails.pan]);

  function downloadXlsxData() {
    //console.log("Inside downloadXLSX Link --->", downloadLink.xlsxLink);
    window.open(downloadLink.xlsxLink, "_self");
  }

  function downloadPdfData() {
    //console.log("Inside downloadPDF Link --->", downloadLink.pdfLink);
    window.open(downloadLink.pdfLink, "_self");
  }

  const handleChange = (event) => {
    setValueSelected(event.target.value);

    if (event.target.value === "All") {
      setRows(dataAll);
      setColsAll(cols);
    } else if (event.target.value === "Contribution") {
      setRows(dataContribution);
      setColsAll(cols);
    } else if (event.target.value === "Distribution") {
      setRows(dataDistribution);
      setColsAll(colsDistribution);
    } else if (event.target.value === "Redemption") {
      setRows(dataRedemption);

      setColsAll(colsRedemption);
    }
  };

  return (
    <div style={{ padding: "10px 10px 0px 20px" }}>
      {loader ? (
        <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            // border: "solid 2px red",
            // boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.07);",
            width: "950px",
            marginBottom: "20px",
          }}
          className={styles.card}
        >
          <CardContent
            style={{
              // border: "solid 2px red",
              width: "97%",
            }}
          >
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <Grid
                item
                xs={4}
                style={{
                  // padding: " 1rem 0 0.25rem 0.5rem",
                  fontSize: "18px",
                  fontWeight: "bolder",
                }}
              >
                Recent Transactions
              </Grid>

              <Grid
                item
                xs={8}
                className={styles.radioButtonCss}
                sx={{ fontFamily: "Lato" }}
              >
                <FormControl sx={{ fontFamily: "Lato" }}>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={valueSelected}
                    onChange={handleChange}
                    sx={{ fontFamily: "Lato" }}
                    row
                  >
                    <FormControlLabel
                      value="All"
                      control={<Radio />}
                      sx={{ fontFamily: "Lato" }}
                      label="All"
                    />
                    <FormControlLabel
                      value="Contribution"
                      control={<Radio />}
                      label="Contribution"
                    />
                    <FormControlLabel
                      value="Redemption"
                      control={<Radio />}
                      label="Redemption"
                    />
                    {AMC_DETAILS.PAYOUT_DISTRUBUTION && (
                      <FormControlLabel
                        value="Distribution"
                        control={<Radio />}
                        label="Distribution"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            {/* Table */}
            <RecentTransactionTable
              name="Recent Transaction"
              data={rows}
              headCells={colsAll}
            />

            {/* Download buttons */}
            <Grid item xs={12} className={styles.buttonGrid}>
              <Grid item xs={6} className={styles.DownloadAsExcelButton}>
                <button
                  onClick={downloadXlsxData}
                  className={
                    downloadLink?.xlsxLink?.length > 0
                      ? styles.htmlButton
                      : styles.htmlButtonDisabled
                  }
                >
                  Download as Excel
                </button>
              </Grid>
              <Grid item xs={6} className={styles.DownloadAsPdfButton}>
                <button
                  onClick={downloadPdfData}
                  className={
                    downloadLink?.xlsxLink?.length > 0
                      ? styles.htmlButton
                      : styles.htmlButtonDisabled
                  }
                >
                  Download as PDF
                </button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : (
        <Loader />
      )}
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    page: state.mainReducer.page,
    userDetails: state.mainReducer.userDetails,
    token: state.mainReducer.token,
  };
};
const mapDispatchtoProps = (dispatch) => {
  return {
    setUserDetails: (val) => {
      dispatch({ type: "CHANGE-USER", userDetails: val });
    },
    setPage: (val) => {
      dispatch({ type: "CHANGE-PAGE", page: val });
    },
  };
};
export default connect(
  mapStatetoProps,
  mapDispatchtoProps
)(RecentTransactionInvestor);
