import { useState } from "react";
import { Auth } from "aws-amplify";
import styles from "../../assets/css/Login.module.css";
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  TextField,
} from "@mui/material";
import CreateForgotPassword from "./CreateForgotPassword";

import { handleSaasAPI } from "../../API/handleAPI";
import { AMC_DETAILS, SAAS_AUTH } from "../../variable";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ReactComponent as CloseSVG } from "../../assets/svg/Close.svg";
import { authStore } from "../../utils/authStore";
import isEmail from "validator/es/lib/isEmail";

function Password({
  switchUser,
  setSwitchUser,
  setPasswordStatus,
  setCogitoUser,
  setClicked,
}) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [headerName, setHeaderName] = useState("Create");
  const [open, setOpen] = useState(false);
  const [openPan, setOpenPan] = useState(false);
  const USER_TYPE = AMC_DETAILS.USER_PORTAL;
  const [showPassword, setShowPassword] = useState(true);

  const [pan, setPan] = useState("");
  const panHelperText =
    "Multiple PANs attached to this email id. Enter the PAN you want to login as Investor/Distributor";

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const navigate = useNavigate();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleOpenPan = () => {
    setOpenPan(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClosePan = () => {
    setOpenPan(false);
  };
  function handlePan(event) {
    setPan(event.target.value);
  }

  // Function for validating password
  async function loginFunction(event, multipan = false) {
    event.preventDefault();
    setClicked(true);
    if (username && password) {
      //console.log("Inside Password Validation");
      const validator = require("validator");
      if (!validator.isEmail(username)) {
        setErrorMessage("Invalid Username");
        setClicked(false);
        return;
      }
      let payload = {
        userName: username,
        code: authStore.getItem("tenantCode"),
        appName: SAAS_AUTH.COGNITO_APPNAME,
      };
      if (multipan) {
        payload = {
          userName: username,
          code: authStore.getItem("tenantCode"),
          appName: SAAS_AUTH.COGNITO_APPNAME,
          pan: pan,
        };
        handleClosePan();
      }
      try {
        // make a api call to get kuid , appClientId and userpoolId for authentication from cognito
        setDisabled(true); // set proceed button disabled
        let responseCognito = await handleSaasAPI("/auth/getKuid", payload);
        console.log("congnito response ", responseCognito);
        if (responseCognito) {
          // configure auth module
          Auth.configure({
            region: SAAS_AUTH.REGION,
            authenticationFlowType: SAAS_AUTH.CONGNITO_AUTHENTICATION_FLOW_TYPE,
            userPoolWebClientId: responseCognito.appClientId,
            userPoolId: responseCognito.userpoolId,
          });

          let cognitoUserId = responseCognito.kuid; // cognitoUserId for sign in
          authStore.setItem("kuid", cognitoUserId);
          const metaData = {
            applicationId: SAAS_AUTH.COGNITO_APPLICATION_ID,
          };

          // perform sign in operation using auth module from aws-amplify

          Auth.signIn(cognitoUserId, password, metaData)
            .then((user) => {
              //console.log(user, "user");
              setCogitoUser(user); // get cognito user
              setPasswordStatus(true); // navigate to otp page
              setErrorMessage("");
              // //console.log(cognitoUserId, "congnitok");
              authStore.setItem("Auth_email", username);
              // authStore.setItem("kuid",cognitoUserId);
            })
            .catch((err) => {
              console.log(err);
              setDisabled(false);
              if (err.message == "Incorrect username or password.") {
                setErrorMessage("Incorrect Username or Password");
              } else if (err.message == "User is not confirmed.") {
                // setErrorMessage("User not confirmed yet");
                Swal.fire({
                  title: "Verify Account",
                  text: "Your account hasn't been verified yet",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#337fc9",
                  confirmButtonText: "Confirm now",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigate("/verifyotp", { state: { email: username } });
                  }
                });
              } else if (err.message == "User does not exist.") {
                setErrorMessage("Incorrect Username or Password");
              } else {
                setErrorMessage("Server time out , Please try again");
              }
            });
        } else {
          setErrorMessage("Server Error , Please try again ");
          setDisabled(false);
        }
      } catch (error) {
        console.log(error);
        setDisabled(false);
        if (error.errorData?.message == "FoundMultipleUsersRequirespan") {
          handleOpenPan();
        } else if (error.errorData?.message == "UserNotFoundException") {
          setErrorMessage("Incorrect Username or Password");
        } else {
          setErrorMessage("Server error , Please try again");
        }
      }
    } else {
      //console.log("every feild is mandatory");
      setErrorMessage("Empty field");
      setDisabled(false);
    }
  }
  // const DefaultTransition = React.forwardRef((props, ref) => (
  //   <Fade {...props} ref={ref} />
  // ));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    borderRadius: 3,
    height: 350,
    p: 4,
  };

  return (
    <div>
      <form onSubmit={loginFunction}>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              border: "2px solid #337fc9",
              borderRadius: "25px",
              width: USER_TYPE == "BOTH" ? "300px" : "200px",
              maxHeight: "5vh",
              minHeight: "5vh",
              display: "flex",
              flexDirection: "row",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Investor | Distributor | Admin */}

            {(USER_TYPE == "INV" || USER_TYPE === "BOTH") && (
              <Button
                style={
                  switchUser === "Investor"
                    ? {
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                        backgroundColor: "#337fc9",
                        borderTopLeftRadius: "25px",
                        borderBottomLeftRadius: "25px",
                      }
                    : switchUser === "Distributor"
                    ? {
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                      }
                    : {
                        borderRight:
                          USER_TYPE === "BOTH" && "2px solid #337fc9",
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                      }
                }
                onClick={() => {
                  setSwitchUser("Investor");
                }}
              >
                <span
                  style={
                    switchUser === "Investor"
                      ? { color: "white" }
                      : { color: "#282c34" }
                  }
                >
                  Investor
                </span>
              </Button>
            )}
            {(USER_TYPE == "DIS" || USER_TYPE === "BOTH") && (
              <Button
                style={
                  switchUser === "Distributor"
                    ? {
                        borderRight: "2px solid #337fc9",
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                        backgroundColor: "#337fc9",
                        borderTopLeftRadius: USER_TYPE == "DIS" && "25px",
                        borderBottomLeftRadius: USER_TYPE == "DIS" && "25px",
                      }
                    : switchUser === "Admin"
                    ? {
                        // borderRight: "2px solid #337fc9",
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                      }
                    : {
                        borderRight: "2px solid #337fc9",
                        maxHeight: "5vh",
                        minHeight: "5vh",
                        width: "100px",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        textTransform: "capitalize",
                      }
                }
                onClick={() => {
                  setSwitchUser("Distributor");
                }}
              >
                <span
                  style={
                    switchUser === "Distributor"
                      ? { color: "white" }
                      : { color: "#282c34" }
                  }
                >
                  Distributor
                </span>
              </Button>
            )}
            <Button
              style={
                switchUser === "Admin"
                  ? {
                      maxHeight: "5vh",
                      minHeight: "5vh",
                      width: "100px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      textTransform: "capitalize",
                      backgroundColor: "#337fc9",
                      borderTopRightRadius: "25px",
                      borderBottomRightRadius: "25px",
                    }
                  : {
                      maxHeight: "5vh",
                      minHeight: "5vh",
                      width: "100px",
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      textTransform: "capitalize",
                    }
              }
              onClick={() => {
                setSwitchUser("Admin");
              }}
            >
              <span
                style={
                  switchUser === "Admin"
                    ? { color: "white" }
                    : { color: "#282c34" }
                }
              >
                Admin
              </span>
            </Button>
          </div>
        </Grid>

        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            maxHeight: "30vh",
            minHeight: "30vh",
          }}
        >
          <TextField
            variant="standard"
            label="Enter Username"
            style={{ border: "none", borderRadius: "0px" }}
            className={styles.inputFormat}
            type="text"
            name="username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            FormHelperTextProps={{ style: { color: "gray" } }}
            error={errorMessage === "Incorrect Username or Password"}
            autoComplete="off"
          />
          {/* {showPassword === "user exists" ? ( */}
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                {" "}
                <TextField
                  variant="standard"
                  label="Enter Password"
                  style={{ border: "none", borderRadius: "0px" }}
                  className={styles.inputFormat}
                  type={showPassword ? "password" : "text"}
                  name="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  FormHelperTextProps={{ style: { color: "gray" } }}
                  error={errorMessage === "Incorrect Username or Password"}
                  autoComplete="off"
                />
              </div>
              <span
                style={{
                  marginTop: ".3rem",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: ".7rem",
                  color: "red",
                  fontFamily: "Lato",
                }}
              >
                {errorMessage}
              </span>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  style={{
                    fontSize: "10px",
                    fontFamily: "Lato",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setHeaderName("Update");
                    handleOpen();
                  }}
                >
                  Forgot Password ?
                </Button>
              </div>
            </div>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
            </Grid>
          </>
          {/* ) : (
          <></>
        )} */}
        </Grid>

        {/* {username && showPassword !== "user exists" ? ( */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            type="submit"
            className={disabled ? "login-disabled" : "loginButton"}
            disabled={username && password ? false : true}
          >
            Proceed
          </button>

          <Grid
            item
            xs={12}
            style={
              switchUser === "Admin"
                ? {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "3vh",
                  }
                : {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: "3vh",
                    visibility: "hidden",
                  }
            }
          >
            <Grid
              item
              xs={9.5}
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                // marginTop: "5px",
                fontFamily: "Lato",
              }}
            >
              Don't have an account?
            </Grid>
            <Grid
              item
              xs={2.5}
              style={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "5px",
                fontFamily: "Lato",
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => navigate("/signUp")}
            >
              SignUp
            </Grid>
          </Grid>
        </div>

        {/* Create/Forgot password modal */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{
            backdropFilter: "blur(10px)",
          }}
        >
          <>
            <CreateForgotPassword
              // headerName={headerName}
              setOpen={setOpen}
              // username={username}
            />
          </>
        </Modal>

        {/* { multiple pan modal} */}
        <Modal
          open={openPan}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ backdropFilter: "blur(10px)" }}
        >
          <>
            <Grid item sx={style}>
              <Grid item className="header">
                <Grid
                  item
                  xs={11}
                  className="title"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {" "}
                </Grid>
                <Grid
                  item
                  xs={1}
                  onClick={handleClosePan}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <CloseSVG />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  //   border: "solid 2px yellow",
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Confirm PAN
              </Grid>

              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    //   border: "solid 2px pink",
                    display: "flex",
                    flexDirection: "column",
                    height: "70%",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "2.5rem",
                      }}
                    >
                      <TextField
                        variant="standard"
                        label="Enter PAN"
                        InputLabelProps={{
                          style: { fontWeight: "800" },
                        }}
                        style={{
                          // border: "solid 2px red",
                          borderRadius: "0px",
                          width: "80%",
                        }}
                        type="text"
                        name="Pan"
                        value={pan}
                        onChange={handlePan}
                        helperText={panHelperText}
                        FormHelperTextProps={{ style: { color: "gray" } }}
                      />
                    </div>
                    <Grid
                      item
                      xs={12}
                      style={{
                        //   border: "solid 2px green",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    ></Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    //   border: "solid 2px green",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    component="span"
                    className={"loginButton"}
                    style={{ textTransform: "capitalize" }}
                    onClick={(e) => {
                      loginFunction(e, true);
                    }}
                  >
                    Proceed
                  </Button>
                </Grid>
              </>
            </Grid>
          </>
        </Modal>
      </form>
    </div>
  );
}

export default Password;
