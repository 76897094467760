import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import camelCase from "../../utils/camelCase";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import styles from "../../assets/css/Cat23.module.css";
import { COLOR_CODE } from "../../variable";

function FormsAndDownloadTable(props) {
  let rows = [];
  let cols = props.headCells;
  //console.log("" + props.data + "\n " + props.name);

  props.data.map((data) => {
    let e = {};
    Object.keys(data).map((key) => {
      e[camelCase(key)] = data[key];
    });
    rows.push(e);
  });
  rows.map((row, index) => (row.id = `${index + 1}`));
  cols.map((col, index) => (col["key"] = `${index + 1}`));

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${
        theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
      }`,
    },
    "& .MuiDataGrid-cell, .MuiDataGrid-cell--textCenter": {
      minHeight: "35px !important",
      maxHeight: "35px !important",
    },
    "& .MuiDataGrid-row": {
      minHeight: "35px !important",
      maxHeight: "35px !important",
    },
    // "& .MuiDataGrid-columnHeaderTitleContainer": {
    //   minHeight: "40px !important",
    //   maxHeight: "40px !important",
    // },
    // "& .MuiDataGrid-columnHeaderDraggableContainer": {
    //   minHeight: "40px !important",
    //   maxHeight: "40px !important",
    // },
    "& .MuiDataGrid-virtualScrollerContent .MuiDataGrid-virtualScrollerContent--overflowed":
      {
        height: "100px",
      },
  }));

  //console.log("Inside Table", props.data);

  return (
    <>
      {props.data.length ? (
        <Box
          sx={{
            height: 400,
            width: 900,

            "& .tableModal--Header": {
              backgroundColor: COLOR_CODE.TABLE_HEADER,
              color: "white",
              fontFamily: "Lato",
              fontSize: "14px",
              // height: "11px",
            },
          }}
        >
          <StyledDataGrid
            style={{
              display: "flex",
              fontFamily: "Lato",
              border: "none",
              fontSize: "14px",
            }}
            rows={rows}
            columns={cols}
            pageSize={8}
            rowsPerPageOptions={[8]}
            disableSelectionOnClick
            experimentalFeatures={{ newEditingApi: true }}
            // hideFooter={true}
          />
        </Box>
      ) : (
        <div
          style={{
            height: "337px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {/* <div className={styles.noInvestmentFirstLineCss}>OOPS !</div> */}
          <div className={styles.noInvestmentSecondLineCss}>
            No {props.name} to show
          </div>
        </div>
      )}
    </>
  );
}

export default FormsAndDownloadTable;
